<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.order_wait_return") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div>
        <custom-table
          ref="customTable"
          :columns="columns"
          :custom-view="customView"
          :footer-actions="footerActions"
          :sums="sums"
          table-height="calc(100vh - 405px)"
          api-url="/orders-wait-return"
          api-download-url="/orders-wait-return-export"
          download-file-name="danh-sach-don-hang.xlsx"
        ></custom-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { CUSTOM_VIEW_NORMAL } from "@/libs/const";

export default {
  name: "WaitReturn",
  data: () => ({
    customView: CUSTOM_VIEW_NORMAL,
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
  }),
  computed: {},
  created() {
    const columns = [
      {
        type: "select-warehouse",
        label: this.$t("labels.warehouse_1"),
        placeholder: this.$t("labels.warehouse_1"),
        name: "id_warehouse",
        hasSort: false,
        sortName: "warehouse_code",
        key: "warehouse_code",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: false,
        sortName: "tracking_id",
        key: "tracking_id",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.customer_order_id"),
        placeholder: this.$t("labels.customer_order_id"),
        name: "customer_order_id",
        hasSort: false,
        sortName: "customer_order_id",
        key: "customer_order_id",
        required: true,
      },
      {
        type: "select-delivery-company",
        label: this.$t("labels.delivery_company"),
        placeholder: this.$t("labels.delivery_company"),
        name: "id_delivery_company",
        hasSort: false,
        sortName: "delivery_company_name",
        key: "delivery_company_name",
        required: true,
      },
      {
        type: "select-e-market",
        label: this.$t("labels.source"),
        placeholder: this.$t("labels.source"),
        class: "c-input-xs input-filter",
        name: "id_e_market",
        hasSort: false,
        sortName: "e_marketplace_name",
        key: "e_marketplace_name",
        required: true,
        params: {
          "is-filter": true,
        },
      },
      {
        type: "select-e-market-shop",
        label: this.$t("labels.shop"),
        placeholder: this.$t("labels.shop"),
        class: "c-input-xs input-filter",
        name: "id_customer_e_marketplace_shop",
        hasSort: false,
        sortName: "customer_e_marketplace_shop_name",
        key: "customer_e_marketplace_shop_name",
        required: true,
        params: {
          "is-check-e-market": false,
        },
      },
    ];
    this.columns = [...columns];
  },
  mounted() {},
  methods: {
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>
